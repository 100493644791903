export default [
  {
    title: 'Dashboard',
    route: 'home',
    icon: 'HomeIcon',
    resource: 'APP_DASHBOARD',
    action: 'read',
  },
  {
    title: 'Müşteri Kartları',
    route: '#',
    icon: 'UsersIcon',
    children: [
      {
        title: 'Oluştur',
        route: '#',
        resource: 'APP_FAVORITES',
        action: 'read',
      },
      {
        title: 'Listele',
        route: '#',
        resource: 'APP_FAVORITES',
        action: 'read',
      },
    ],
  },
  {
    title: 'Görüşmeler',
    route: '#',
    icon: 'MessageCircleIcon',
    children: [
      {
        title: 'Oluştur',
        route: '#',
        resource: 'APP_FAVORITES',
        action: 'read',
      },
      {
        title: 'Açık Görüşmeler',
        route: '#',
        resource: 'APP_FAVORITES',
        action: 'read',
      },
      {
        title: 'Tüm Görüşmeler',
        route: '#',
        resource: 'APP_FAVORITES',
        action: 'read',
      },
    ],
  },
  {
    title: 'Hatırlatmalar',
    route: '#',
    icon: 'BellIcon',
    children: [
      {
        title: 'Oluştur',
        route: '#',
        resource: 'APP_FAVORITES',
        action: 'read',
      },
      {
        title: 'Hatırlatmalarım',
        route: '#',
        resource: 'APP_FAVORITES',
        action: 'read',
      },
    ],
  },
  {
    title: 'Teklifler',
    route: '#',
    icon: 'FileTextIcon',
    children: [
      {
        title: 'Oluştur',
        route: '#',
        resource: 'APP_FAVORITES',
        action: 'read',
      },
      {
        title: 'Açık Teklifler',
        route: '#',
        resource: 'APP_FAVORITES',
        action: 'read',
      },
      {
        title: 'Tüm Teklifler',
        route: '#',
        resource: 'APP_FAVORITES',
        action: 'read',
      },
    ],
  },
  {
    title: 'Servis Formları',
    route: '#',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'Oluştur',
        route: '#',
        resource: 'APP_FAVORITES',
        action: 'read',
      },
      {
        title: 'Açık İşlemler',
        route: '#',
        resource: 'APP_FAVORITES',
        action: 'read',
      },
      {
        title: 'Tüm İşlemler',
        route: '#',
        resource: 'APP_FAVORITES',
        action: 'read',
      },
    ],
  },
  {
    header: 'Yönetim',
    resource: 'ADMIN',
    action: 'read',
  },
  {
    title: 'Bayiler',
    route: '#',
    icon: 'ArchiveIcon',
    children: [
      {
        title: 'Oluştur',
        route: '#',
        resource: 'ADMIN_ORDERS',
        action: 'read',
      },
      {
        title: 'Bayi Listesi',
        route: '#',
        resource: 'ADMIN_ORDERS',
        action: 'read',
      },
    ],
  },
  {
    title: 'Ürünler',
    route: '#',
    icon: 'PrinterIcon',
    children: [
      {
        title: 'Oluştur',
        route: '#',
        resource: 'ADMIN_ORDERS',
        action: 'read',
      },
      {
        title: 'Ürün Listesi',
        route: '#',
        resource: 'ADMIN_ORDERS',
        action: 'read',
      },
      {
        title: 'Kategori Listesi',
        route: '#',
        resource: 'ADMIN_ORDERS',
        action: 'read',
      },
    ],
  },
  {
    title: 'Tanımlamalar',
    route: '#',
    icon: 'DatabaseIcon',
    children: [
      {
        title: 'Ülke',
        route: '#',
        resource: 'ADMIN_ACTIVITY_AREAS',
        action: 'read',
      },
      {
        title: 'Şehir',
        route: '#',
        resource: 'ADMIN_MARKETING_FOCUS',
        action: 'read',
      },
      {
        title: 'Temas Tipi',
        route: '#',
        resource: 'ADMIN_STARTUP_CONFIGS',
        action: 'read',
      },
      {
        title: 'Sektör',
        route: '#',
        resource: 'ADMIN_STARTUP_STATUSES',
        action: 'read',
      },
      {
        title: 'Para Birimleri',
        route: '#',
        resource: 'ADMIN_STARTUP_STATUSES',
        action: 'read',
      },
      {
        title: 'Birim Tipleri',
        route: '#',
        resource: 'ADMIN_STARTUP_STATUSES',
        action: 'read',
      },
      {
        title: 'Müşteri Tipleri',
        route: '#',
        resource: 'ADMIN_STARTUP_STATUSES',
        action: 'read',
      },
      {
        title: 'Teklif Özel Şartları',
        route: '#',
        resource: 'ADMIN_STARTUP_STATUSES',
        action: 'read',
      },
      {
        title: 'Görüşme Konuları',
        route: '#',
        resource: 'ADMIN_STARTUP_STATUSES',
        action: 'read',
      },
      {
        title: 'Görüşme Durumları',
        route: '#',
        resource: 'ADMIN_STARTUP_STATUSES',
        action: 'read',
      },
      {
        title: 'Vergi Oranları',
        route: '#',
        resource: 'ADMIN_STARTUP_STATUSES',
        action: 'read',
      },
      {
        title: 'Servis İşlem Tipleri',
        route: '#',
        resource: 'ADMIN_STARTUP_STATUSES',
        action: 'read',
      },
      {
        title: 'Servis Durumları',
        route: '#',
        resource: 'ADMIN_STARTUP_STATUSES',
        action: 'read',
      },
    ],
  },
  {
    title: 'Kullanıcılar',
    route: '#',
    icon: 'UsersIcon',
    children: [
      {
        title: 'Kullanıcılar',
        route: 'Users',
        resource: 'ADMIN_USERS',
        action: 'read',
      },
      {
        title: 'Kullanıcı Yetkileri',
        route: 'UserTypes',
        resource: 'ADMIN_USER_TYPES',
        action: 'read',
      },
    ],
  },
  {
    title: 'Ayarlar',
    route: '#',
    icon: 'SettingsIcon',
    children: [
      {
        title: 'SMTP',
        route: 'Smtp',
        resource: 'ADMIN_SMTP',
        action: 'read',
      },
    ],
  },
]
